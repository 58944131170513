import axios from "@/plugins/axios.js";

/**
 * 学员列表
 * @return {Promise}
 */
export function getTableList(data) {
  return axios.get("/classStu/findClassStudents", {
    params: data,
  });
}

/**
 * 新增学员
 * @return {Promise}
 */
export function addStudents(data) {
  return axios.post("/classStu/addClassStudents", data);
}

/**
 * 编辑学员
 * @return {Promise}
 */
export function editStudents(data) {
  return axios.get("/classStu/editClassStudents", {
    params: data,
  });
}

/**
 * 删除学员
 * @return {Promise}
 */
export function deletedStudents(data) {
  return axios.get("/classStu/delClassStudents", {
    params: data,
  });
}

/**
 * 导入操作
 * @classId 班级id
 */
export function importClassStudents(data) {
  return axios.post("/classStu/importClassStudents", data);
}
/**
 * 新建学生重复校验
 */
export function addClassStudentCheck(data) {
  return axios.post("/classStu/addClassStudentCheck", data);
}
/**
 * 编辑学生重复校验
 */
export function editClassStudentCheck(data) {
  return axios.post("/classStu/editClassStudentCheck", data);
}
