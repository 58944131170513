<template>
  <!-- 学员管理页面-->
  <div>
    <div class="studentManage">
      <div class="studentManage-top f-c">
        <div class="f-l studentManage-top-btn" @click="goBack">
          <img
            src="@/assets/images/icon/navtop_back.png"
            class="studentManage-tool-btnicon"
          />
          <span>{{ $t("el.common.back") }}</span>
        </div>
        <div class="f-l studentManage-top-text">
          {{ $t("el.studentManage.studentMag1") }} {{ $route.query.name }}
          {{ $t("el.studentManage.studentMag2") }}
        </div>
      </div>
      <div class="f-c studentManage-tool">
        <div class="f-l f-c">
          <div class="f-l">{{ $t("el.studentManage.studentName") }}</div>
          <el-input
            class="f-l"
            v-model="sercher.fullName"
            :placeholder="$t('el.common.pleaseEnter')"
            size="small"
            style="width: 180px; margin-right: 24px"
          ></el-input>
          <button style="width: 65px" @click="getData">
            <img
              src="@/assets/images/icon/icon_search.png"
              class="studentManage-tool-btnicon"
            />
            <span>{{ $t("el.common.search") }}</span>
          </button>
        </div>

        <div class="f-r studentManage-tool-title">
          <button
            style="width: 82px"
            @click="
              formdata.fullName = '';
              formdata.id = '';
              studentVisible = true;
              isedit = false;
            "
          >
            <img
              src="@/assets/images/icon/icon_add.png"
              class="studentManage-tool-btnicon"
            />
            <span>{{ $t("el.common.create") }}</span>
          </button>
          <el-upload
            class="upload-demo"
            style="display: inline-block; float: left"
            :show-file-list="false"
            :action="url"
            :headers="importHeaders"
            :on-success="success"
            :before-upload="handleChange"
            :file-list="fileList"
            :on-error="handleError"
          >
            <button style="width: 82px">
              <img
                src="@/assets/images/icon/icon_Import.png"
                class="studentManage-tool-btnicon"
                style="width: 16px"
              />
              <span>{{ $t("el.common.import") }}</span>
            </button>
          </el-upload>

          <button style="width: 110px" @click="downTemplate">
            {{ $t("el.studentManage.downloadTemplate") }}
          </button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="studentManage-table">
        <el-table
          v-loading="loading"
          :data="tableData"
          border
          :header-cell-class-name="headerStyle"
          :cell-class-name="cellStyle"
          style="width: 100%"
        >
          <el-table-column
            prop="fullName"
            :show-overflow-tooltip="true"
            :label="$t('el.studentManage.studentNameN')"
            min-width="32%"
          >
          </el-table-column>
          <el-table-column
            prop="createTime"
            :show-overflow-tooltip="true"
            :label="$t('el.studentManage.createTime')"
            min-width="26%"
          >
          </el-table-column>
          <el-table-column :label="$t('el.common.operate')" min-width="42%">
            <template slot-scope="scope">
              <el-button
                type="text"
                :loading="loading"
                @click="
                  formdata.id = scope.row.id;
                  formdata.fullName = scope.row.fullName;
                  studentVisible = true;
                  isedit = true;
                "
                >{{ $t("el.common.edit") }}</el-button
              >
              <el-button
                type="text"
                :loading="loading"
                style="margin-left: 40px"
                @click="
                  deleteVisible = true;
                  formdata.id = scope.row.id;
                "
                >{{ $t("el.common.delete") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!--分页  -->
      <div class="studentManage-page" v-if="total > 0">
        <el-pagination
          layout="prev, pager, next"
          :current-page.sync="params.page"
          :page-size="params.limit"
          :total="total"
        >
        </el-pagination>
        <span class="el-pagination__total"
          >{{ $t("el.common.totalname") }}{{ Math.ceil(total / params.limit)
          }}{{ $t("el.common.pages") }}&nbsp;&nbsp;{{ total
          }}{{ $t("el.common.items") }}
        </span>
      </div>
      <!-- 删除弹窗 -->
      <el-dialog
        width="464px"
        class="delete"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :visible.sync="deleteVisible"
      >
        <div slot="title" class="red delete-header">
          <img src="@/assets/images/icon/msgbox_delete.png" />
          <span class="delete-header-text">{{
            $t("el.common.deleteConfirm")
          }}</span>
        </div>
        <div class="delete-content" style="word-break: break-word">
          {{ $t("el.studentManage.deleteTips") }}
        </div>
        <span slot="footer" class="delete-footer">
          <el-button
            type="primary"
            :loading="loading"
            size="medium"
            @click="deleteItem"
            >{{ $t("el.common.confirm") }}</el-button
          >
          <el-button @click="deleteVisible = false" size="medium">{{
            $t("el.common.cancel")
          }}</el-button>
        </span>
      </el-dialog>
      <!-- 新增编辑弹窗 -->
      <el-dialog
        width="464px"
        class="class"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :visible.sync="studentVisible"
      >
        <div slot="title" class="red">
          {{
            isedit
              ? $t("el.studentManage.editStudent")
              : $t("el.studentManage.addStudent")
          }}
        </div>
        <div class="el-dialog__content class-content">
          <div>
            <span class="class-content-redtip"> * </span>
            <span class="class-content-label"
              >{{ $t("el.studentManage.studentNameN") }} :
            </span>
            <el-input
              :placeholder="$t('el.common.pleaseEnter')"
              v-model="formdata.fullName"
              class="class-content-input"
            ></el-input>
            <div class="class-content-warn" v-if="ischeck.fullName">
              <span>{{ $t("el.studentManage.enterStudentName") }}</span>
            </div>
          </div>
        </div>
        <span slot="footer" class="class-footer">
          <el-button
            type="primary"
            :loading="loading"
            size="medium"
            @click="sureStudent"
            >{{ $t("el.common.confirm") }}</el-button
          >
          <el-button @click="studentVisible = false" size="medium">{{
            $t("el.common.cancel")
          }}</el-button>
        </span>
      </el-dialog>
      <!-- 新增和编辑有重复弹框 -->
      <el-dialog
        width="464px"
        class="class"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :visible.sync="errstudentVisible"
      >
        <div slot="title" class="red">
          {{
            isedit
              ? $t("el.studentManage.editStudent")
              : $t("el.studentManage.addStudent")
          }}
        </div>
        <!-- <div class="el-dialog__content class-content"> -->
        <div>
          <span v-if="!isedit" class="class-content-label">{{
            $t("el.studentManage.addStuTips")
          }}</span>
          <span v-if="isedit" class="class-content-label">{{
            $t("el.studentManage.editStudentTips")
          }}</span>
        </div>

        <!-- </div> -->
        <span slot="footer" v-if="isedit" class="class-footer editfoot">
          <el-button
            size="medium"
            @click="pardonsureStudent"
            style="width: 175px"
            >{{ $t("el.studentManage.conformChange") }}</el-button
          >
          <el-button
            @click="errstudentVisible = false"
            size="medium"
            type="primary"
            :loading="loading"
            style="width: 150px"
            >{{ $t("el.studentManage.backModify") }}</el-button
          >
        </span>
        <span slot="footer" v-if="!isedit" class="class-footer editfoot">
          <el-button
            size="medium"
            @click="pardonsureStudent"
            style="width: 175px"
            >{{ $t("el.studentManage.confirmAdd") }}</el-button
          >
          <el-button
            @click="errstudentVisible = false"
            size="medium"
            type="primary"
            :loading="loading"
            style="width: 150px"
            >{{ $t("el.studentManage.backModify") }}</el-button
          >
        </span>
      </el-dialog>
      <!-- 导入Excel表有重复弹框 -->
      <el-dialog
        width="464px"
        class="class"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :visible.sync="excstudentVisible"
      >
        <div slot="title" class="red">
          {{ $t("el.studentManage.importProm") }}
        </div>
        <!-- <div class="el-dialog__content class-content"> -->
        <div>
          <span class="class-content-label">{{
            $t("el.studentManage.hasSameStu")
          }}</span>
        </div>

        <!-- </div> -->
        <span slot="footer" class="class-footer editfoot">
          <el-button @click="sureupload" size="medium" style="width: 170px">{{
            $t("el.studentManage.continueImport")
          }}</el-button>
          <el-button
            type="primary"
            :loading="loading"
            size="medium"
            @click="noupload"
            style="width: 150px"
            >{{ $t("el.studentManage.dontImport") }}</el-button
          >
        </span>
      </el-dialog>
      <!-- 导入数据库有重复弹框 -->
      <el-dialog
        width="464px"
        class="class"
        center
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body="false"
        :visible.sync="sqlstudentVisible"
      >
        <div slot="title" class="red">
          {{ $t("el.studentManage.importProm") }}
        </div>
        <!-- <div class="el-dialog__content class-content"> -->
        <div>
          <span class="class-content-label">{{
            $t("el.studentManage.skipOrContinue")
          }}</span
          ><br />
          <div class="exportStudentName" v-html="windowdata">
            <!-- <span v-for="(item, index) in duplicateFullName" :key="index">
              {{ item }}, </span
            >这{{ duplicateFullName.length }}名同学。 -->
            <!-- {{windowdata}} -->
          </div>
        </div>

        <!-- </div> -->
        <span slot="footer" class="class-footer editfoot">
          <el-button
            @click="sureuploadsql(1)"
            size="medium"
            style="width: 170px"
            >{{ $t("el.studentManage.continueImport") }}</el-button
          >
          <el-button
            type="primary"
            :loading="loading"
            size="medium"
            @click="sureuploadsql(0)"
            >{{ $t("el.studentManage.skipRepeat") }}</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  getTableList,
  addStudents,
  editStudents,
  deletedStudents,
  importClassStudents,
  addClassStudentCheck,
  editClassStudentCheck,
} from "@/api/home/studentmanage";
import { download } from "@/utils";
import { getToken } from "@/core/auth";
export default {
  name: "studentManage",
  // 注册组件
  components: {},
  // 定义数据
  data() {
    return {
      tableData: [],
      windowdata: null,
      importStuMouldDtoList: null, // 导入的数据
      deleteVisible: false, // 删除弹窗
      studentVisible: false, // 新增编辑班级弹窗
      errstudentVisible: false, // 新增编辑班级有重复弹窗
      sqlstudentVisible: false, // 导入数据库有重复弹框
      excstudentVisible: false, // 表格导入有重复
      dataDuplicate: null, // 数据库导入有重复
      duplicateFullName: [], // 数据库重复的名单
      isedit: false, // 是否是编辑
      sercher: {
        // 搜索
        fullName: "",
      },
      formdata: {
        // 新增编辑弹窗
        classId: this.$route.query.id,
        id: "",
        fullName: "", // 学生姓名
      },
      ischeck: {
        fullName: false,
      },
      loading: false,
      params: {
        limit: 10,
        offset: 0,
        page: 1,
      },
      total: 0,
      fileList: [],
      url:
        process.env.VUE_APP_BASE_API +
        "/classStu/importDateDuplicate/" +
        this.$route.query.id,
      importHeaders: {
        Authorization: getToken(),
        "Accept-Language": this.$i18n.locale === "en_US" ? "en" : "zh",
      },
    };
  },
  // 计算属性
  computed: {},
  // 侦听器
  watch: {
    // 监听页数
    "params.page"(newVal, oldVal) {
      // 改变参数
      this.params.offset = this.params.limit * (this.params.page - 1);
      this.getData();
    },
  },
  // 创建完成钩子
  created() {
    this.getData();
  },
  // 挂在完成钩子
  mounted() {},
  // 方法定义
  methods: {
    headerStyle({ row, rowIndex }) {
      return "bskHeader";
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      return "bskCell";
    },
    goBack() {
      this.$router.go(-1);
      // this.$router.push({
      //   name: "classManage",
      // });
    },
    sureStudent() {
      // 新增编辑确认
      var check = "";
      check = this.$utils.check(this.formdata, [
        { key: "fullName", require: true, msg: "fullName" },
      ]);
      if (check === 1) {
        for (var i in this.ischeck) {
          this.ischeck[i] = false;
        }
        if (this.isedit) {
          // 编辑
          // this.loading = true;
          let params = {
            id: this.formdata.id,
            fullName: this.formdata.fullName,
          };
          editClassStudentCheck(params).then((res) => {
            if (res.data.isDataDuplicate) {
              this.errstudentVisible = true;
            } else {
              editStudents(this.formdata)
                .then(({ data }) => {
                  this.$message({
                    type: "success",
                    message: this.$t("el.studentManage.editSuccess"),
                  });
                  this.studentVisible = false;
                  this.getData();
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          });
        } else {
          // 新增加
          // this.loading = true;
          let params = {
            classId: this.$route.query.id,
            fullName: this.formdata.fullName,
          };
          addClassStudentCheck(params).then((res) => {
            if (res.data.isDataDuplicate) {
              this.errstudentVisible = true;
            } else {
              addStudents(this.formdata)
                .then(({ data }) => {
                  this.$message({
                    type: "success",
                    message: this.$t("el.studentManage.addSuccess") + "!",
                  });
                  this.studentVisible = false;
                  this.getData();
                  this.loading = false;
                })
                .catch(() => {
                  this.loading = false;
                });
            }
          });
        }
      } else {
        this.ischeck[check] = true;
      }
    },
    pardonsureStudent() {
      this.errstudentVisible = false;
      if (this.isedit) {
        // 确定编辑结果
        editStudents(this.formdata)
          .then(({ data }) => {
            this.$message({
              type: "success",
              message: "编辑成功!",
            });
            this.studentVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        addStudents(this.formdata)
          .then(({ data }) => {
            this.$message({
              type: "success",
              message: this.$t("el.studentManage.addSuccess") + "!",
            });
            this.studentVisible = false;
            this.getData();
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    getData() {
      // 获取列表数据
      this.loading = true;
      getTableList({
        ...this.params,
        classId: this.$route.query.id,
        fullName: this.sercher.fullName,
      })
        .then(({ data }) => {
          this.tableData = data.records;
          this.total = data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    deleteItem() {
      // 删除学员
      this.loading = true;
      deletedStudents(this.formdata)
        .then(({ data }) => {
          this.$message({
            type: "success",
            message: this.$t("el.studentManage.deleteSuccess") + "！",
          });
          this.deleteVisible = false;
          this.getData();
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    downTemplate() {
      // 下载模板
      download(
        "/model/modelDownload",
        {
          type: "students",
          language: this.$i18n.locale === "en_US" ? "en" : "zh",
        },
        {
          method: "get",
        }
      );
    },
    // 导入execl
    onSuccess() {},
    handleChange(file, fileList) {
      const m = file.name.match(/\.(\w+)(#|\?|$)/);
      const fileType = (m && m[1]).toString().toLowerCase();
      if (fileType !== "xls" && fileType !== "xlsx") {
        this.$message.error(this.$t("el.studentManage.fileTypes"));
        return false;
      }
    },
    /**
     * 数组截取
     * @array 数组
     * @subGroupLength 截取的index
     * return [[],[]]
     */
    group(array, subGroupLength) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
      }
      return newArray;
    },
    success(response, file, fileList) {
      if (response.status != 200) {
        this.$message.error(`${response.message}`);
      } else {
        this.dataDuplicate = response.data.dataDuplicate;
        this.duplicateFullName = response.data.duplicateFullName;
        this.importStuMouldDtoList = response.data.importStuMouldDtoList;
        let array1 = [];
        let array2 = [];
        let array3 = [];
        if (response.data.duplicateFullName.length > 0) {
          if (response.data.duplicateFullName.length < 6) {
            this.windowdata = `<span>${response.data.duplicateFullName.join(
              ","
            )}</span>`;
          }
          if (
            response.data.duplicateFullName.length < 11 &&
            response.data.duplicateFullName.length > 5
          ) {
            array1 = this.group(response.data.duplicateFullName, 5);
            this.windowdata = `<span>${array1[0].join(",")}<br>${array1[1].join(
              ","
            )}</span>`;
          }
          if (response.data.duplicateFullName.length > 10) {
            array2 = this.group(response.data.duplicateFullName, 5);
            array3 = this.group(array2[1], 5);
            this.windowdata = `<span>${array2[0].join(",")}<br>${array3[0].join(
              ","
            )}...等${response.data.duplicateFullName.length}名学生</span>`;
          }
        }
        if (response.data.excelDuplicate) {
          this.excstudentVisible = true;
        } else {
          if (response.data.dataDuplicate) {
            this.sqlstudentVisible = true;
          } else {
            this.sureupload();
          }
        }
      }
    },
    handleError() {
      this.$message.error(this.$t("el.common.importError"));
    },
    noupload() {
      this.excstudentVisible = false;
      this.importStuMouldDtoList = null;
      this.dataDuplicate = null;
      this.duplicateFullName = [];
    },
    sureupload() {
      let classid = this.$route.query.id;
      let data = {
        classId: classid,
        operationType: 1,
        importStuMouldDtoList: this.importStuMouldDtoList,
      };
      this.excstudentVisible = false;
      if (this.dataDuplicate) {
        this.sqlstudentVisible = true;
      } else {
        importClassStudents(data).then((res) => {
          if (res.status == 200) {
            this.$message({
              message: this.$t("el.studentManage.importSuccess"),
              type: "success",
            });
            this.getData();
            this.importStuMouldDtoList = null;
            this.dataDuplicate = null;
            this.duplicateFullName = [];
          }
        });
      }
    },
    sureuploadsql(type) {
      let classid = this.$route.query.id;
      let data = {
        classId: classid,
        operationType: type,
        importStuMouldDtoList: this.importStuMouldDtoList,
      };
      importClassStudents(data).then((res) => {
        if (res.status == 200) {
          this.$message({
            message: this.$t("el.studentManage.importSuccess"),
            type: "success",
          });
          this.getData();
          this.importStuMouldDtoList = null;
          this.dataDuplicate = null;
          this.duplicateFullName = [];
          this.sqlstudentVisible = false;
          this.errstudentVisible = false;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.studentManage {
  background: #fff;
  padding: 27px 40px 42px 40px;
  border-radius: 4px;
  min-height: 565px;
  box-sizing: border-box;
  &-top {
    height: 32px;
    line-height: 32px;
    &-btn {
      width: 90px;
      height: 32px;
      background: rgba(245, 246, 250, 1);
      border-radius: 12px;
      text-align: center;
      margin-right: 28px;
      font-size: 13px;
      // font-family: "MicrosoftYaHei";
      color: rgba(68, 68, 68, 1);
      cursor: pointer;
      img {
        width: 12px;
        margin-right: 4px;
      }
    }
    &-text {
      font-size: 14px;
      // font-family: MicrosoftYaHei;
      color: @themeBlue;
    }
  }
  &-tool {
    height: 32px;
    line-height: 32px;
    margin: 20px 0 25px 0;
    button {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(249, 249, 249, 1);
      height: 32px;
      display: flex;
      background: @themeBlue;
      border-radius: 5px;
      float: left;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      cursor: pointer;
      outline: none;
    }
    &-title {
      min-width: 120px;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(100, 108, 149, 1);
      line-height: 19px;
    }
    &-btnicon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
  }
  &-table {
    /deep/.bskHeader {
      background-color: #f5f6f7 !important;
      color: #323232;
      text-align: center !important;
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      font-weight: normal;
      height: 50px;
      padding: 0;
    }
    /deep/.bskCell {
      text-align: center !important;
      color: #707276;
      font-size: 14px;
      height: 46px;
      padding: 0;
      .cell {
        white-space: nowrap;
      }
    }
    /deep/.el-button--text {
      border: 0;
      color: @themeBlue;
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background: #f1f6ff;
    }
  }

  &-page {
    padding-top: 39px;
    text-align: center;
    /deep/ .el-pager li {
      height: 30px;
      line-height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      padding: 0 0px;
      margin: 0 5px;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
      font-weight: 400;
      color: rgba(40, 40, 40, 1);
    }
    /deep/ .el-pager li.active {
      background: @themeBlue;
      color: #fff;
      font-size: 13px;
      // font-family: "SourceHanSansCN-Medium", "SourceHanSansCN";
    }
    /deep/.el-pagination__total {
      font-size: 12px;
      // font-family: "MicrosoftYaHei";
      color: rgba(40, 40, 40, 1);
    }
    /deep/.el-pagination button {
      height: 30px;
      line-height: 30px;
    }
    /deep/ .el-pagination button:hover {
      color: @themeBlue;
    }
  }
  /deep/ .el-table th.gutter {
    display: table-cell !important;
  }
}
.delete {
  /deep/ .el-dialog__header {
    padding: 24px 20px 10px;
  }
  /deep/.el-dialog--center .el-dialog__body {
    padding: 24px 20px 10px;
  }
  &-header {
    display: flex;
    justify-content: center;
    img {
      width: 20px;
      margin-right: 8px;
    }
    &-text {
      font-size: 18px;
      // font-family: "MicrosoftYaHei-Bold", "MicrosoftYaHei";
      font-weight: bold;
      color: rgba(245, 34, 45, 1);
    }
  }
  &-content {
    text-align: center;
    padding: 23px 0px 45px;
    font-size: 19px;
    // font-family: "MicrosoftYaHei";
    color: rgba(100, 108, 149, 1);
    line-height: 37px;
    letter-spacing: 1px;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 30px;
  }
  button {
    width: 84px;
    border-radius: 5px;
    margin: 0 18px;
  }
}
.class {
  &-content {
    padding-left: 9px;
    height: 118px;
    &-redtip {
      font-size: 16px;
      // font-family: MicrosoftYaHei;
      color: rgba(245, 34, 45, 1);
      line-height: 21px;
    }
    &-label {
      font-size: 16px;
      // font-family: MicrosoftYaHei;
      color: rgba(70, 70, 70, 1);
      line-height: 21px;
    }
    &-input {
      width: 260px;
    }
    &-warn {
      font-size: 14px;
      // font-family: "MicrosoftYaHei";
      color: rgba(245, 34, 45, 1);
      line-height: 19px;
      margin: 8px 0 0 88px;
    }
  }
  /deep/ .el-button {
    width: fit-content;
    border-radius: 5px;
    margin: 0 14px;
  }
  /deep/ .el-dialog__footer {
    padding-bottom: 30px;
  }
}
.studentManage-page {
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}
.exportStudentName {
  line-height: 22px;
  margin-top: 16px;
}
.editfoot {
  display: flex;
  flex-direction: row-reverse;
}
</style>
